<template>
    <div class="apply-form-container" v-loading="loading">
        <div class="apply-form-main">
            <h2>{{applyTitle}}</h2>
            <el-form 
                :model="applyForm" 
                :rules="rules" 
                ref="applyForm" 
                label-width="180px" 
                label-position="left"
                class="apply-form">

                <!-- 开票信息 -->
                <h3>开票信息</h3>
                <el-form-item label="开票主体" prop="invoiceEntity">
                    <!-- @change="handleInvoiceEntityChange" -->
                    <el-select 
                        v-model="applyForm.invoiceEntity" 
                        placeholder="请选择" 
                        class="input-normal">
                        <el-option
                            v-for="(item, index) in invoiceEntityArray"
                            :key="'entiry_'+ index"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开票抬头" prop="invoiceHeader">
                    <!-- <el-select 
                        class="input-normal"
                        v-if="hasDefaultContract" 
                        v-model="applyForm.invoiceHeader" 
                        placeholder="请选择">
                        <el-option
                            v-for="(item, idx) in invoiceHeaderOptions"
                            :key="'op_' + idx"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select> -->
                    <el-autocomplete
                        v-if="hasDefaultContract" 
                        class="input-normal"
                        v-model="applyForm.invoiceHeader" 
                        :fetch-suggestions="querySearch"
                        placeholder="请输入内容"
                        >
                    </el-autocomplete>
                    <el-input 
                        v-else
                        class="input-normal"
                        v-model="applyForm.invoiceHeader"
                        maxlength="100"
                        >
                    </el-input>
                    <a class="extra-right" @click="useCommonInfo">使用常用开票信息</a>
                </el-form-item>
                <el-form-item label="开票类型" prop="invoiceType">
                    <el-select v-model="applyForm.invoiceType" placeholder="请选择" class="input-normal">
                        <el-option label="普票" value="0"></el-option>
                        <el-option label="专票" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开票内容" prop="invoiceContent">
                    <el-select v-model="applyForm.invoiceContent" placeholder="请选择" class="input-normal">
                        <el-option label="服务费" value="服务费"></el-option>
                        <el-option label="招聘服务费" value="招聘服务费"></el-option>
                        <el-option label="咨询服务费" value="咨询服务费"></el-option>
                        <el-option label="其他人力资源服务费" value="其他人力资源服务费"></el-option>
                        <el-option label="信息服务费" value="信息服务费"></el-option>
                        <el-option label="软件服务费" value="软件服务费"></el-option>
                        <el-option label="技术服务费" value="技术服务费"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开票金额" prop="invoiceAmount">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.invoiceAmount"
                        disabled
                        >
                    </el-input>
                    <a class="extra-right" @click="handleEditPerformance">编辑业绩</a>
                </el-form-item>
                
                <el-form-item label="付款方式" prop="paymentMethodType">
                    <el-select 
                        v-model="applyForm.paymentMethodType" 
                        placeholder="请选择" 
                        :disabled="isApplyBalance || isApplyNegative || isEdit"
                        class="select-sm">
                        <el-option label="全款" value="0"></el-option>
                        <el-option label="首款" value="1"></el-option>
                        <el-option label="尾款" value="2" v-if="!['0', '1'].includes(paymentMethodType)"></el-option>
                    </el-select>

                    <el-form-item label="付款比例"
                        v-if="applyForm.paymentMethodType =='1' || applyForm.paymentMethodType =='2'"
                        prop="paymentProportion" class="percent-wrap" label-width="150px">
                        <el-select 
                            v-model="applyForm.paymentProportion" 
                            placeholder="请选择"
                            :disabled="isApplyBalance || isApplyNegative"
                            class="select-sm">
                            <el-option label="90%" value="0.9"></el-option>
                            <el-option label="80%" value="0.8"></el-option>
                            <el-option label="70%" value="0.7"></el-option>
                            <el-option label="60%" value="0.6"></el-option>
                            <el-option label="50%" value="0.5"></el-option>
                            <el-option label="40%" value="0.4"></el-option>
                            <el-option label="30%" value="0.3"></el-option>
                            <el-option label="20%" value="0.2"></el-option>
                            <el-option label="10%" value="0.1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="抵扣方式" prop="deductionType" v-if="isApplyNegative">
                    <el-select 
                        class="input-normal"
                        v-model="applyForm.deductionType" 
                        placeholder="请选择"
                        >
                        <el-option label="抵扣本单" value="0"></el-option>
                        <!-- <el-option label="抵扣其它单" value="1"></el-option> -->
                    </el-select>
                </el-form-item>
                <el-form-item label="抵扣人选" prop="deductionPeople" v-if="applyForm.deductionType== '1'">
                    <el-select 
                        class="input-normal"
                        popper-class="deduction-people-select"
                        v-model="applyForm.deductionPeople" 
                        placeholder="搜索候选人姓名或者顾问姓名查找"
                        ref="deductionPeopleSelect"
                        filterable
                        >
                        <div class="opt-top">
                            <div class="opt-top-cell">候选人</div>
                            <div class="opt-top-cell">顾问</div>
                        </div>
                        <!-- :label="people.candidateId"  -->
                        <el-option 
                            v-for="(people, index) in deductionUsers"
                            :key="'people_'+ index"
                            :label="people.candidateName + '——' + people.deductionUserName"
                            :value="people.candidateName + '——' + people.deductionUserName">
                            <div class="opt-item" @click.stop="handlePeopleClick(people)">
                                <div class="opt-item-l ellipsis" :title="people.candidateName">
                                    {{people.candidateName}}
                                </div>
                                <div class="opt-item-m ellipsis" :title="people.deductionUserName">
                                    {{people.deductionUserName}}
                                </div>
                                <div class="opt-item-r">
                                    <i class="el-icon-check"></i>
                                </div>
                            </div>
                        </el-option>
                        
                        <div class="empty-data" slot="empty" v-if="deductionUsers.length ==0">
                            <span class="empty-img "></span>
                            <p>暂无数据</p>
                        </div>

                        <div class="select-btns-wrap">
                            <el-button class="sure" @click="handleBtnHold(1)">确定</el-button>
                        </div>
                    </el-select>
                </el-form-item>
                <el-form-item label="发票快递地址" prop="expressAddress">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.expressAddress"
                        maxlength="100"
                        >
                    </el-input>
                </el-form-item>
                <el-form-item label="收件人" prop="expressReceiver">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.expressReceiver"
                        maxlength="20"
                        >
                    </el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="expressPhone">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.expressPhone"
                        maxlength="100"
                        >
                    </el-input>
                </el-form-item>


                <!-- 公司信息 -->
                <h3>公司信息</h3>
                <el-form-item label="公司名称" prop="companyName">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.companyName"
                        maxlength="100"
                        >
                    </el-input>
                    <a class="extra-right" ref="copyBtn" :data-clipboard-text="applyForm.companyName">复制发票抬头</a>
                </el-form-item>
                <el-form-item label="纳税人识别号" prop="companyTaxpayerNo">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.companyTaxpayerNo"
                        maxlength="20"
                        >
                    </el-input>
                </el-form-item>
                <el-form-item label="注册地址" prop="companyRegisterAddr">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.companyRegisterAddr"
                        maxlength="100"
                        >
                    </el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="companyPhone">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.companyPhone"
                        maxlength="100"
                        >
                    </el-input>
                </el-form-item>
                <el-form-item label="开户银行" prop="companyBankofDeposit">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.companyBankofDeposit"
                        maxlength="50"
                        >
                    </el-input>
                </el-form-item>
                <el-form-item label="银行账号" prop="companyBankCardNo">
                    <el-input 
                        class="input-normal"
                        v-model.trim="applyForm.companyBankCardNo"
                        maxlength="50"
                        >
                    </el-input>
                </el-form-item>

                <!-- 业绩相关 -->
                <h3>业绩相关</h3>
                <el-form-item 
                    v-for="(pItem, index) in performances"
                    :key="'performance_' + index"
                    :label="pItem.label + '/' + (pItem.type == 0 ? '固定分成': '分成比例') +'/分成值'">
                    <el-input 
                        disabled
                        class="input-normal"
                        v-model="pItem.value"
                        :title="pItem.value"
                        >
                    </el-input>
                </el-form-item>
                
                <el-form-item label="开票原因" prop="invoiceReason" v-if="isApplyNegative">
                    <el-select v-model="applyForm.invoiceReason" placeholder="请选择申请负数发票的原因" @change="changeInvoiceReason">
                        <el-option
                            v-for="item in invoiceReasonOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-if="isApplyNegative">
                    <el-form-item label="离职日期" v-if="applyForm.invoiceReason==0" prop="resignationDate">
                        <el-date-picker
                            class="input-normal"
                            v-model="applyForm.resignationDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择离职日期">
                        </el-date-picker>
                    </el-form-item>
                </template>

                <el-form-item label="上传离职证明" v-if="isApplyNegative && applyForm.invoiceReason==0"  prop="leavingCertificateAttachments">
                    <!-- :on-remove="handleRemove" -->
                    <el-upload
                        class="apply-upload"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        accept=".txt, .gif, .jpg, .png, .doc, .docx, .pdf, .zip, .rar"
                        ref="leavingCertificateAttachmentsUpload"
                        :multiple="false"
                        :limit="5"
                        :file-list="leavingFileList"
                        :on-remove="(file, fileList) => {handleRemove(file, fileList, 3)}"
                        :on-exceed="handleExceed"
                        :http-request="handleUploadLeavingFileList" >

                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传txt、gif、jpg、png、doc、docx、pdf、zip、rar文件，且不超过2.0M</div>
                    </el-upload>
                </el-form-item>
                <!-- <template v-else-if="isApplyBalance">

                </template> -->
                <template v-else>
                    <el-form-item label="入职确认的入职日期" prop="actualEntryDate">
                        <el-date-picker
                            class="input-normal"
                            @change="actualEntryDateChange"
                            v-model="applyForm.actualEntryDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择入职确认中的入职日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="过保日期" prop="overWarrantyDate">
                        <el-date-picker
                            class="input-normal"
                            @change="overWarrantyDateChange"
                            v-model="applyForm.overWarrantyDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择过保日期">
                        </el-date-picker>
                        <!-- @change="handleDateChange" 
                         @change="handleDateChange"-->
                    </el-form-item>
                    <el-form-item label="合同约定的回款日期" prop="contractAgreedRefundDate">
                        <el-date-picker
                            class="input-normal"
                            v-model="applyForm.contractAgreedRefundDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            @change="handleDateChange"
                            placeholder="请选择合同约定的回款日期">
                        </el-date-picker>
                    </el-form-item>
                </template>
                
                <el-form-item label="备注" prop="ownerRemarks">
                    <el-input 
                        class="input-normal"
                        v-model="applyForm.ownerRemarks"
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 6}"
                        maxlength="100"
                        show-word-limit
                        >
                    </el-input>
                </el-form-item>

                <el-form-item label="上传入职证明" v-if="!(isApplyBalance || isApplyNegative)"  prop="Attachments">
                    <el-upload
                        class="apply-upload"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        accept=".bmp, .jpg, .png"
                        ref="attachmentUpload"
                        :multiple="false"
                        :limit="5"
                        :file-list="fileList"
                        :on-remove="handleRemove"
                        :on-exceed="handleExceed"
                        :http-request="handleUpload" >

                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传bmp, jpg, png文件，且不超过2.0M</div>
                    </el-upload>
                </el-form-item>

                <el-form-item label="上传付款通知函" v-if="!isApplyNegative"  prop="paymentNoticeAttachments">
                    <!-- :on-remove="handleRemove" -->
                    <el-upload
                        class="apply-upload"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        accept=".txt, .doc, .docx, .pdf, .xls, .xlsx"
                        ref="paymentNoticeAttachmentsUpload"
                        :multiple="false"
                        :limit="5"
                        :file-list="paymentNoticeFileList"
                        :on-remove="(file, fileList) => {handleRemove(file, fileList, 2)}"
                        :on-exceed="handleExceed"
                        :http-request="handleUploadPaymentNoticeFileList" >

                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传txt、doc、docx、pdf、xls、xlsx文件，且不超过2.0M</div>
                    </el-upload>
                </el-form-item>

                <el-form-item label="">
                    <el-checkbox v-model="applyForm.isSaveCommonInfo">保留到常用开票信息中</el-checkbox>
                </el-form-item>

                <el-form-item>
                    <el-button 
                        class="apply-bottom-btn" 
                        type="primary" 
                        :loading="holdLoading"
                        :disabled="holdLoading"
                        @click="submitForm('applyForm')">提 交</el-button>
                    <el-button 
                        class="apply-bottom-btn" 
                        :loading="holdLoading"
                        :disabled="holdLoading"
                        @click="resetForm('applyForm')">取消</el-button>
                </el-form-item>
            </el-form>

            <!-- 常用开票信息弹窗 -->
            <common-ticket-info
                ref="commonTicketInfo"
                @select-cb="selectCb"
            />
            
            <!-- 编辑业绩弹出窗口 -->
            <!-- :is-disable="(isApplyNegative || isApplyBalance)" -->
            <!-- type="edit" -->
            <offer-dialog
                ref="offerDialog"
                @successEditOffer="editOfferSuccess"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import ClipboardJS from 'clipboard';    //文字复制插件
import { currency as currencyFilter } from '#/js/filters/number.js';
import CommonTicketInfo from './component/common-ticket-info.vue';
import OfferDialog from '#/component/common/dialog/offer-dialog.vue';
import myService from '#/js/service/myService.js';
import floatingListService from '#/js/service/floatingListService.js';
import CustomerService from '#/js/service/customerService.js';
import {
    invoiceEntityMap,
} from '#/views/customers/config/config-map';

export default {
    name: 'apply-invoice',
	components: {
        CommonTicketInfo,
        OfferDialog,
	},
	data() {
		return {
            // applyTitle: '',
            invoiceReasonOptions:[{
                value: 0,
                label:'候选人离职'
            },{
                value: 1,
                label:'客户信息有误'
            },{
                value: 2,
                label:'其他'
            }],
            applyForm: {
                invoiceReason: '', // 开票原因
                invoiceEntity: '',    // 主体 
                // applicationType: '', // 0-正数 1-负数
                invoiceHeader: '',    // 发票抬头
                invoiceType: '',    // 发票类型 0-普票 1-专票
                invoiceContent: '',    // 发票内容 
                invoiceAmount: '',    // 开票金额
                paymentMethodType: '0',    // 付款方式 0-全款 1-首款 2-尾款
                paymentProportion: '',    // 付款比例
                deductionType: '',  // 抵扣方式
                deductionPeople: '',
                deductionRecommendId: '',
                expressAddress: '',    // 发票快递地址
                expressReceiver: '',    // 收件人
                expressPhone: '',    // 联系电话

                companyName: '', // 公司名称
                companyTaxpayerNo: '', // 纳税人识别号
                companyRegisterAddr: '', // 注册地址
                companyPhone: '', // 联系电话
                companyBankofDeposit: '', // 开户银行
                companyBankCardNo: '', // 银行账号

                // TS: '',
                // BD: '',
                // PM: '',
                // CS: '',

                overWarrantyDate: '', // 过保日期
                actualEntryDate: '', // 入职确认中的入职日期
                resignationDate: '', // 离职日期
                contractAgreedRefundDate: '', // 合同约定的回款日期
                ownerRemarks: '', // 备注
                Attachments: [], // **上传入职证明
                leavingCertificateAttachments: [], // **上传离职证明
                paymentNoticeAttachments: [], // **上传付款通知函
                isSaveCommonInfo: false, // 保留到常用开票信息中
            },
            fileList: [], // 入职证明文件列表
            leavingFileList: [], // 离职证明文件列表
            paymentNoticeFileList: [], // 付款通知函文件列表
            currentFile: null,  // 当前要上传的文件缓存

            defaultInvoiceAmount: '',
            defaulPperformanceSplits: [],

            performances: [],

            invoiceEntityArray: [
                // '上海科锐福克斯人才顾问有限公司',
                // '深圳八爪网络科技有限公司'
            ],

            rules: {
                invoiceEntity: [
                    { required: true, message: '请选择开票主体', trigger: ['blur', 'change'] },
                ],
                invoiceHeader: [
                    { required: true, message: '请填写发票抬头', trigger: ['blur', 'change'] },
                ],
                invoiceType: [
                    { required: true, message: '请选择发票类型', trigger: ['blur', 'change'] },
                ],
                invoiceContent: [
                    { required: true, message: '请选择发票内容', trigger: ['blur', 'change'] },
                ],
                invoiceAmount: [
                    { required: true, message: '请选择开票金额', trigger: 'blur' },
                ],
                paymentMethodType: [
                    { required: true, message: '请选择付款方式', trigger: ['blur', 'change'] },
                ],
                paymentProportion: [
                    { required: true, message: '请选择付款比例', trigger: ['blur', 'change'] },
                ],
                deductionType: [
                    { required: true, message: '请选择抵扣方式', trigger: ['blur', 'change'] },
                ],
                deductionPeople: [
                    { required: true, message: '请选择抵扣人选', trigger: ['blur', 'change'] },
                ],
                expressAddress: [
                    { required: true, message: '请填写发票快递地址', trigger: 'blur' },
                ],
                expressReceiver: [
                    { required: true, message: '请填写收件人', trigger: 'blur' },
                ],
                expressPhone: [
                    { required: true, message: '请填写联系电话', trigger: 'blur' },
                    // { validator: this.validateMobile, trigger: ['blur', 'change'] }
                ],

                companyName: [
                    { required: true, message: '请填写公司名称', trigger: 'blur' },
                ],
                companyTaxpayerNo: [
                    { required: true, message: '请填写纳税人识别号', trigger: 'blur' },
                    { validator: this.validateTaxpayerNo, trigger: ['blur', 'change'] },
                ],
                companyRegisterAddr: [
                    { required: true, message: '请填写注册地址', trigger: 'blur' },
                ],
                companyPhone: [
                    { required: true, message: '请填写联系电话', trigger: 'blur' },
                    // { validator: this.validateMobile, trigger: ['blur', 'change'] }
                ],
                companyBankofDeposit: [
                    { required: true, message: '请填写开户银行', trigger: 'blur' },
                ],
                companyBankCardNo: [
                    { required: true, message: '请填写银行账号', trigger: 'blur' },
                    // { validator: this.validateBankCard, trigger: ['blur', 'change'] }
                ],

                overWarrantyDate: [
                    { required: true, message: '请选择过保日期', trigger: ['blur', 'change'] },
                ],
                actualEntryDate: [
                    { required: true, message: '请选择入职确认中的入职日期', trigger: 'blur' },
                    { validator: this.validateEntryDate, trigger: ['blur', 'change'] }
                ],
                contractAgreedRefundDate: [
                    { required: true, message: '请选择合同约定的回款日期', trigger: 'blur' },
                ],
                
                invoiceReason: [
                    { required: true, message: '请选择申请负数发票的原因', trigger: 'change' },
                ],
                resignationDate: [
                    { required: true, message: '请选择离职日期', trigger: 'blur' },
                ],
                Attachments: [
                    { required: true, message: '请上传入职证明', trigger: 'blur' },
                ],
                leavingCertificateAttachments: [
                    { required: true, message: '请上传离职证明', trigger: 'blur' },
                ],
                paymentNoticeAttachments: [
                    { required: true, message: '请上传付款通知函', trigger: 'blur' },
                ],
            },
            loading: false,
            // firmMembers: [], // 公司成员
            holdLoading: false,

            peopleFilterList: [
                {name: 'aaa'},
                {name: 'bbb'},
            ],
            deductionUsers: [],

            contractInfo: {},
            invoiceHeaderOptions: [],
            hasDefaultContract: false,
        };
    },
    computed: {
        isEdit() {
            return this.$route.query.isEdit;
        },
        jobOwnerId() {
            return this.$route.query.jobOwnerId;
        },
        recommendId() {
            return this.$route.query.recommendId;
        },
        invoiceId() {
            return this.$route.query.invoiceId;
        },
        // 重新申请
        isReApplication() {
            return this.$route.query.isReApplication == "true";
        },
        isReCreateApplication() {
            return this.$route.query.isReCreateApplication == "true";
        },
        refApplicationId() {
            return this.$route.query.refApplicationId;
        },
        // 是否为负数发票
        isApplyNegative() {
            return this.$route.query.isApplyNegative == "true";
        },
        // 是否为尾款发票
        isApplyBalance() {
            return this.$route.query.isApplyBalance != 'undefined' &&this.$route.query.isApplyBalance == "true";
        },
        paymentMethodType() {
            return this.$route.query.paymentMethodType == 'undefined' ? null: this.$route.query.paymentMethodType;
        },
        // 是否要请求附件列表
        isAttachment() {
            return this.$route.query.isAttachment == "false";
        },
        applyTitle() {
            if(this.isReApplication) {
                return '重新申请开票';
            }
            if(this.isApplyNegative) {
                if(this.isEdit) return `修改负数发票申请开票`;
                return '负数发票申请开票';
            }
            if(this.isApplyBalance) {
                if(this.isEdit) return `修改尾款发票申请开票`;
                return '申请尾款开票';
            }
            if(this.invoiceId) {
                return '修改申请开票';
            }
            return '申请开票';
        },
        computedPercent() {
            if(this.applyForm.paymentMethodType == '0') {
                return this.isApplyNegative ? -1: 1;
            } else {
                return this.isApplyNegative ? -this.applyForm.paymentProportion : +this.applyForm.paymentProportion;
            }
        },
        
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        // hasDefaultContract() {
        //     return JSON.stringify(this.contractInfo) == '{}'
        // },
    },
    watch: {
        computedPercent(val) {
            // this.generatePerformances(this.computedPercent);
            this.generatePerformances(val);
        },

        fileList(val) {
            this.applyForm.Attachments = val.map(item => item.FileId);
            this.$refs.applyForm.validateField("Attachments", (valid) => {});
        },
        leavingFileList(val) {
            this.applyForm.leavingCertificateAttachments = val.map(item => item.FileId);
            this.$refs.applyForm.validateField("leavingCertificateAttachments", (valid) => {});
        },
        paymentNoticeFileList(val) {
            this.applyForm.paymentNoticeAttachments = val.map(item => item.FileId);
            this.$refs.applyForm.validateField("paymentNoticeAttachments", (valid) => {});
        },
    },
    created() {
        // 状态[新增、编辑]
        // 类型【全款、首付、尾款、负数】
        // 1.编辑和尾款，负数发票都需要请求数据初始化表单

        // isEdit 0 新增、1 编辑
        // paymentType 0全款、1首付、2尾款、3负数
        // applicationStatus 0未开票 1 已驳回 3未回款 7已回款 9已取消
        this.getInvoiceEntity();
        if(this.invoiceId) {
            // 编辑
            this.getInvoiceDetail();
        } else {
            // 新增
            this.getDefaultPerformance();
            if(this.$route.query.recommendId && this.$route.query.recommendId != 'undefined') {
                this.getDefaultContract(this.$route.query.recommendId);
            }
        }
        
        if(this.isApplyNegative) {
            this.getDeductionUsers();
        }
    },
	mounted() {
        const copyLink = new ClipboardJS(this.$refs.copyBtn);
        copyLink.on('success', (res) => {
            shortTips("复制成功");
        });
        copyLink.on('error', (err) => {
            shortTips("复制失败，请手动复制。");
        });
	},
    filters: {},
	methods: {
        changeInvoiceReason(value) {
            // console.log(value);
            this.applyForm.resignationDate = '';
        },
        validateTaxpayerNo(rule, value, callback) {
            if(value.length != 18) {
                callback('正确的纳税人识别号为18位');
            }
            callback();
        },
        validateEntryDate(rule, value, callback) {
            if(this.applyForm.overWarrantyDate && 
                !moment(value).isBefore(this.applyForm.overWarrantyDate)) {
                callback(`入职日期要早于过保日期`);
            }
            if(this.applyForm.contractAgreedRefundDate && 
                !moment(value).isBefore(this.applyForm.contractAgreedRefundDate)) {
                callback(`入职日期要早于回款日期`);
            }
            callback();
        },
        validateMobile(rule, value, callback) {
            // let regexMobileExternal = /^(\+|00)\d{8,15}$/,
            //     regexMobileInternal = /^(1[3-9])\d{9}$/;
            // if(value.length < 11) return;
            let regexMobileExternal = /^(\d{7,11})$/;
            let arr = value.match(regexMobileExternal);
            if(arr === null) {
                callback(new Error('请填写有效的手机号码或者座机'));
            } else {
                callback();
            }
        },
        validateBankCard(rule, value, callback) {
            // let validateBankCard = /^(\d{16}|\d{19})$/;
            let validateBankCard = /^(\d{12,20})$/;
            let arr = value.match(validateBankCard);
            if(arr === null) {
                callback(new Error('请填写有效的银行卡号'));
            } else {
                callback();
            }
        },

        getDeductionUsers() {
            floatingListService
                .getDeductionUsers(this.invoiceId)
                .then(res => {
                    this.deductionUsers = res;
                })
        },

        // 获取默认业绩
        getDefaultPerformance() {
            myService.getEditOfferInfo({
                recommendId: this.recommendId
            }).then(res => {
                this.defaultInvoiceAmount = res.serviceCharge;
                this.defaulPperformanceSplits = res.performanceSplits;
                
                this.generatePerformances(this.computedPercent);
            }).catch(err => {
                console.log(err);
            });
        },

        // 获取默认合同数据并回填
        getDefaultContract(recommendId) {
            CustomerService
                .getDefaultContractInfo(recommendId)
                .then(res => {
                    console.log(`getDefaultContractInfo`, res)
                    if(res) {
                        this.contractInfo = res || {};

                        this.applyForm.invoiceEntity = invoiceEntityMap[res.signedEntity];
                        // this.applyForm.invoiceHeader = res.customerSubject;
                        this.invoiceHeaderOptions = res.customerSubject.split(",").map(it=> ({value: it}));
                        this.hasDefaultContract = true;

                        const payItem = res.paymentSettings[0];
                        // 尾款申请不用填充 首付和全款需要
                        if(!this.isApplyBalance) {
                            this.applyForm.paymentMethodType = String(payItem.paymentMethodType);
                            if(payItem.paymentMethodType == 1) {
                                this.applyForm.paymentProportion = String(payItem.firstPaymentProportion/100);
                            }
                        } else {
                            // 尾款申请填充日期
                            this.dealIsBalanceAndNegative(this.applyForm);
                            this.$nextTick(() => {
                                this.getBalanceDates(this.applyForm.actualEntryDate);
                            });
                        }
                    } else {
                        this.dealIsBalanceAndNegative(this.applyForm);
                    }
                    
                });
                
        },

        getBalanceDates(val) {
            const ps = this.contractInfo.paymentSettings[0];
            const _dateType = ps.overWarrantyPeriodDateType;
            const _len = ps.overWarrantyPeriod;
            console.log(val, _dateType, _len)

            this.applyForm.overWarrantyDate = this.getDate(val, _dateType, _len)
            console.log(this.applyForm.overWarrantyDate)

            const _balanceRefundPeriodStartType = ps.balanceRefundPeriodStartType;
            const _balanceRefundPeriodLen = ps.balanceRefundPeriod;
            const _balanceRefundPeriodDateType = ps.balanceRefundPeriodDateType;

            if(_balanceRefundPeriodStartType == 0) {// 入职后
                this.applyForm.contractAgreedRefundDate = this.getDate(val, _balanceRefundPeriodDateType, _balanceRefundPeriodLen);
            }

            if(_balanceRefundPeriodStartType == 2) {// 过保
                this.applyForm.contractAgreedRefundDate = this.getDate(this.applyForm.overWarrantyDate, _balanceRefundPeriodDateType, _balanceRefundPeriodLen);
            }
        },

        getInvoiceEntity() {
            floatingListService.getInvoiceEntity().then(res => {
                this.invoiceEntityArray = res;
            }).catch(err => {
                console.log(err);
            });
        },
        // 生成业绩
        generatePerformances(percent =1) {
            this.applyForm.invoiceAmount = (this.defaultInvoiceAmount * percent).toFixed(2);
            this.performances = [];
            
            this.defaulPperformanceSplits.forEach((item, index) => {
                const _obj = {};
                _obj.label = item.name;
                _obj.type = item.type;
                item.splitItems.forEach((subItem, sIndex) => {
                    if(sIndex > 0) {
                        if(item.type == 0) {
                            _obj.value += ` | ${subItem.realName}/${subItem.value}/${(subItem.value* percent).toFixed(2)}`;
                        } else {
                            _obj.value += ` | ${subItem.realName}/${subItem.value}%/${((this.defaultInvoiceAmount/100)*subItem.value* percent).toFixed(2)}`;
                        }
                    } else {
                        if(item.type == 0) {
                            _obj.value = `${subItem.realName}/${subItem.value}/${(subItem.value* percent).toFixed(2)}`;
                        } else {
                            _obj.value = `${subItem.realName}/${subItem.value}%/${((this.defaultInvoiceAmount/100)*subItem.value* percent).toFixed(2)}`;
                        }
                    }
                });
                this.performances.push(_obj);
            });
        },
        getInvoiceDetail() {
            this.loading = true;
            floatingListService
                .getInvoiceItemDetail(this.invoiceId)
                .then(res => {
                    console.log(`getInvoiceItemDetail:`, res)
                    Object.assign(this.applyForm, {
                        ...res,
                        invoiceType: '' + res.invoiceType,
                        paymentMethodType: '' + res.paymentMethodType,
                        paymentProportion: res.paymentProportion ? ('' + res.paymentProportion) : null,
                    });
                    if(res.deductionType != null) {
                        this.applyForm.deductionType = String(res.deductionType);
                    }
                })
                .then(() => {
                    // 负数和修改是不需要重新填充合同的
                    if(!this.isApplyNegative && this.isEdit!='1' && this.$route.query.recommendId && this.$route.query.recommendId != 'undefined' && !this.isReApplication) {
                        this.getDefaultContract(this.$route.query.recommendId);
                    }
                })
                // .then(() => {
                //     if(JSON.stringify(this.contractInfo) == '{}') {
                //         this.dealIsBalanceAndNegative(this.applyForm);
                //     }
                // })
                .then(() => {
                    this.getInvoicingAttachmentsList(0);
                    if(this.isApplyNegative) {
                        this.getInvoicingAttachmentsList(3);
                    } else if(!this.isAttachment) {
                        this.getInvoicingAttachmentsList(2);
                    }
                })
                .finally(() => {
                   
                    this.getDefaultPerformance();
                    this.loading = false;
                });
        },
        
        dealIsBalanceAndNegative(res) {
            if(this.isApplyBalance) {
                this.$set(this.applyForm, 'paymentMethodType', '2');
                // 重新申请也相当于编辑，不用减
                if(!this.isEdit && !this.isReApplication) {
                    this.$set(this.applyForm, 'paymentProportion', '' + (1.0 - res.paymentProportion).toFixed(1));
                }

                if(!this.isReApplication && !this.isEdit) {
                    this.$set(this.applyForm, 'ownerRemarks', '');
                }
            }
            if(this.isApplyNegative) {
                // 重新申请负数发票要求显示备注
                if(!this.isReApplication && !this.isEdit) {
                    this.$set(this.applyForm, 'ownerRemarks', '');
                }
                
                this.$set(this.applyForm, 'deductionType', res.deductionType == null ? '0' : ''+res.deductionType);
                if(res.deductionType == 1) {
                    this.$set(this.applyForm, 'deductionPeople', res.deductionCandidateName + '——' + res.deductionOwnerName);
                }
            }
        },
        // 获取附件列表
        getInvoicingAttachmentsList(type) {
            const params = {
                    invoiceId: this.invoiceId,
                    attachmentType: type,
                };
            floatingListService
                .invoicingAttachmentsList(params)
                .then(res => {
                    // this.fileList = res.map(item => ({ FileId: item.fileId, name: item.fileName }))
                    if(type == 3) {
                        this.leavingFileList = res.map(item => ({ FileId: item.fileId, name: item.fileName }))
                    } else if(type == 2) {
                        this.paymentNoticeFileList = res.map(item => ({ FileId: item.fileId, name: item.fileName }))
                    } else {
                        this.fileList = res.map(item => ({ FileId: item.fileId, name: item.fileName }))
                    }
                }).finally(() => { 
                    // this.loading = false;
                });
        },

        // 创建提交申请
        // type 正数，尾款，负数
        invoiceApply(type) {
            //*     "applicationType": 0, 0为正数，1为负数
            //*     "applicationStatus": 0, 0未开票 1 已驳回 3未回款 7已回款 9已取消
            //*     "refApplicationId": "", 关联id
            
            const params = {
                ...this.applyForm,
                ownerId: this.jobOwnerId,
                recommendId: this.recommendId,
                refApplicationId: '', // 关联id
                applicationStatus: 0, // 0未开票 1 已驳回 3未回款 7已回款 9已取消
                applicationType: 0, // 0为正数，1为负数
            };
            if(type == 'isApplyNegative') {
                params.applicationType = 1;
                params.refApplicationId = this.invoiceId;
            }
            if(type == 'isApplyBalance') {
                params.refApplicationId = this.invoiceId;
            }
            if(type == 'isReCreate') {
                params.refApplicationId = this.invoiceId;
                if(this.paymentMethodType == 2) {
                    params.refApplicationId = this.refApplicationId;
                }
            }
            this.holdLoading = true;
            floatingListService.invoiceApply(params).then(res => {
                shortTips(`申请创建成功！`);
                window.location.href = `/#/floatingList/apply`;
                // 成功后刷新列表
                // window.postMessage("un-apply:refresh");
            }).finally(() => {
                this.holdLoading = false;
            });
        },

        // 修改
        applyEdit() {
            const params = {
                ...this.applyForm,
            };
            this.holdLoading = true;
            floatingListService.invoiceApplyEdit(params).then(res => {
                shortTips(`申请编辑成功！`);
                window.location.href = `/#/floatingList/apply`;
            }).finally(() => {
                this.holdLoading = false;
            });
        },

        reApply() {
            const params = {
                ...this.applyForm,
                isReApplication: this.isReApplication,
            };
            this.holdLoading = true;
            floatingListService.invoiceApplyReapply(params).then(res => {
                shortTips(`申请编辑成功！`);
                window.location.href = `/#/floatingList/apply`;
            }).finally(() => {
                this.holdLoading = false;
            });
        },
        
        selectCb(params) {
            Object.assign(this.applyForm, {
                ...params,
                id: this.applyForm.id,
            });
            this.$set(this.applyForm, 'invoiceHeader', params.companyName);
        },

        handleEditPerformance() {
            this.$refs.offerDialog.showHandle(this.recommendId, null, '编辑业绩', true, false, {
                hasValidInvoice: this.isApplyNegative || this.isApplyBalance
            }, 'edit');
        },

        editOfferSuccess(params) {
            // this.applyForm.invoiceAmount = params.serviceCharge;
            this.defaultInvoiceAmount = params.serviceCharge;
            
            this.defaulPperformanceSplits = params.performanceSplits;
            this.generatePerformances(this.computedPercent);
            shortTips('编辑成功!');
        },

        useCommonInfo() {
            this.$refs['commonTicketInfo'].show();
        },

        // 附件操作--Start
        // handlePreview(file) {
        //     console.log(file);
        // },

        handleExceed(files, fileList) {
            shortTips(`最多只能上传5个文件！`);
        },

        // 上传入职证明
        handleUpload({file, onSuccess}) {
            // console.log(file.type)
            let isImage = false;
            if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/bmp') {
                isImage = true;
            } else {
                isImage = false;
            }
            // console.log(isImage)
            if(!isImage) {
                shortTips("只能上传bmp, jpg, png文件");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }

            const fileName = file.name.slice(0, file.name.lastIndexOf("."));
            if(fileName.length > 100) {
                shortTips("文件名长度不能超过100！");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }
            
            if(file.size / 1024 / 1024 > 2) {
                shortTips("文件大小不得超过2M");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }

            if(this.fileList.findIndex(item => item.name == file.name) >= 0) {
                shortTips("不要重复上传同一文件！");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }
            
            let formData = new FormData();
            formData.append(`file`, file);

            floatingListService.invoicingAttachmentsAdd(formData).then(res => {
                this.fileList.push({
                    name: file.name,
                    size: file.size,
                    FileId: res,
                })
            }).catch(err => {
                this.$refs.attachmentUpload.uploadFiles.pop();
                if(err.error) shortTips(err.error.description);
            });
        },
        // 上传离职证明
        handleUploadLeavingFileList({file, onSuccess}) {
            // console.log(file.type)
            let fileType = [".txt",".gif",".jpg",".png",".doc",".docx",".pdf",".zip",".rar"];
            var isType = false;
            var fileEnd = file.name.substring(file.name.lastIndexOf("."));
            // console.log(fileEnd)
            if(fileType && fileType.length>0){
                for(var i = 0; i < fileType.length; i++){
                    if(fileType[i ]== fileEnd){
                        isType = true;
                        break;
                    }
                }
            }
            if(!isType){
                shortTips("只能上传txt、gif、jpg、png、doc、docx、pdf、zip、rar文件");
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                return;
            }

            const fileName = file.name.slice(0, file.name.lastIndexOf("."));
            if(fileName.length > 100) {
                shortTips("文件名长度不能超过100！");
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                return;
            }
            
            if(file.size / 1024 / 1024 > 2) {
                shortTips("文件大小不得超过2M");
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                return;
            }

            if(this.leavingFileList.findIndex(item => item.name == file.name) >= 0) {
                shortTips("不要重复上传同一文件！");
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                return;
            }
            
            let formData = new FormData();
            formData.append(`file`, file);

            floatingListService.invoicingAttachmentsAdd(formData).then(res => {
                this.leavingFileList.push({
                    name: file.name,
                    size: file.size,
                    FileId: res,
                })
            }).catch(err => {
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                if(err.error) shortTips(err.error.description);
            });
        },
        // 上传付款通知函
        handleUploadPaymentNoticeFileList({file, onSuccess}) {
            // console.log(file.type)
            let fileType = [".txt",".doc",".docx",".pdf",".xls",".xlsx"];
            var isType = false;
            var fileEnd = file.name.substring(file.name.lastIndexOf("."));
            // console.log(fileEnd)
            if(fileType && fileType.length>0){
                for(var i = 0; i < fileType.length; i++){
                    if(fileType[i ]== fileEnd){
                        isType = true;
                        break;
                    }
                }
            }
            if(!isType){
                shortTips("只能上传txt、doc、docx、pdf、xls、xlsx文件文件");
                this.$refs.paymentNoticeAttachmentsUpload.uploadFiles.pop();
                return;
            }

            const fileName = file.name.slice(0, file.name.lastIndexOf("."));
            if(fileName.length > 100) {
                shortTips("文件名长度不能超过100！");
                this.$refs.paymentNoticeAttachmentsUpload.uploadFiles.pop();
                return;
            }
            
            if(file.size / 1024 / 1024 > 2) {
                shortTips("文件大小不得超过2M");
                this.$refs.paymentNoticeAttachmentsUpload.uploadFiles.pop();
                return;
            }

            if(this.paymentNoticeFileList.findIndex(item => item.name == file.name) >= 0) {
                shortTips("不要重复上传同一文件！");
                this.$refs.paymentNoticeAttachmentsUpload.uploadFiles.pop();
                return;
            }
            
            let formData = new FormData();
            formData.append(`file`, file);

            floatingListService.invoicingAttachmentsAdd(formData).then(res => {
                this.paymentNoticeFileList.push({
                    name: file.name,
                    size: file.size,
                    FileId: res,
                })
            }).catch(err => {
                this.$refs.paymentNoticeAttachmentsUpload.uploadFiles.pop();
                if(err.error) shortTips(err.error.description);
            });
        },
        handleRemove(file, fileList, type) {
            const params = {
                FileId: file.FileId,
                InvoiceId: this.invoiceId,
            };
            floatingListService.invoicingAttachmentsDelete(params).then(res => {
                shortTips(`删除成功！`)
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                // const removeIndex = this.fileList.findIndex(item => item.FileId == file.FileId);
                // this.fileList.splice(removeIndex, 1);
                if(type == 3) {
                    const removeIndex = this.leavingFileList.findIndex(item => item.FileId == file.FileId);
                    this.leavingFileList.splice(removeIndex, 1);
                } else if(type == 2) {
                    const removeIndex = this.paymentNoticeFileList.findIndex(item => item.FileId == file.FileId);
                    this.paymentNoticeFileList.splice(removeIndex, 1);
                } else {
                    const removeIndex = this.fileList.findIndex(item => item.FileId == file.FileId);
                    this.fileList.splice(removeIndex, 1);
                }
            });
        },
        // 附件操作--End


        submitForm(formName) {
            this.$refs[formName].validate((valid, invalidFields) => {
                if (valid) {
                    // console.log(this.applyForm);
                    if(this.invoiceId) {
                        if(this.isReApplication) {
                            if(this.isReCreateApplication) {
                                return this.invoiceApply('isReCreate');
                            }else {
                                return this.reApply();
                            }
                        }
                        if(this.isEdit) {
                            return this.applyEdit();
                        }
                        if(this.isApplyNegative) {
                            return this.invoiceApply('isApplyNegative');
                        }
                        if(this.isApplyBalance) {
                            return this.invoiceApply('isApplyBalance');
                        }
                        return this.applyEdit();
                    } else {
                        return this.invoiceApply();
                    }
                } else {
                    // 自动跳转到第一个验证未通过处
                    setTimeout(() =>{
                        if($('.el-form-item__error')[0]) {
                            let bodyScroll = document.body.scrollTop || document.documentElement.scrollTop;
                            let topDistance = bodyScroll + $('.el-form-item__error')[0].getBoundingClientRect().top - 100;
                            document.documentElement.scrollTop = topDistance;
                            document.body.scrollTop = topDistance;
                        }
                    }, 300);
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            // window.opener.postMessage(data, "*");
        },
        handleDateChange() {
            this.$refs.applyForm.validateField("actualEntryDate", (valid) => { });
        },
        handlePeopleClick(people) {
            this.applyForm.deductionPeople = people.candidateName + '——' + people.deductionUserName;
            this.applyForm.deductionRecommendId = people.deductionRecommendId;
        },
        handleBtnHold() {
            if(!this.applyForm.deductionPeople) return shortTips(`请选择抵扣人选再提交`);
            this.$refs.deductionPeopleSelect.blur();
        },
        getDate(val, type, len) {
            console.log(`args------------`, arguments)
            if(!val) {
                return moment().format('YYYY-MM-DD');
            }
            let _newTime;
            switch (type) {
                case 0:
                    _newTime = moment(val).add("days", len);
                    break;
                case 1:
                    _newTime = moment(this.addWorkingDays(val, len));
                    break;
                case 2:
                    _newTime = moment(val).add("month", len);
                    break;
                default:
                    break;
            }
            console.log(_newTime)
            console.log(_newTime.format('YYYY-MM-DD'))
            return _newTime.format('YYYY-MM-DD');
        },
        actualEntryDateChange(val) {
            if(this.hasDefaultContract) {
                if(this.isApplyBalance) {
                    const ps = this.contractInfo.paymentSettings[0];
                    const _dateType = ps.overWarrantyPeriodDateType;
                    const _len = ps.overWarrantyPeriod;

                    this.applyForm.overWarrantyDate = this.getDate(val, _dateType, _len)

                    const _balanceRefundPeriodStartType = ps.balanceRefundPeriodStartType;
                    const _balanceRefundPeriodLen = ps.balanceRefundPeriod;
                    const _balanceRefundPeriodDateType = ps.balanceRefundPeriodDateType;

                    if(_balanceRefundPeriodStartType == 0) {// 入职后
                        this.applyForm.contractAgreedRefundDate = this.getDate(val, _balanceRefundPeriodDateType, _balanceRefundPeriodLen);
                    }

                    if(_balanceRefundPeriodStartType == 2) {// 过保
                        this.applyForm.contractAgreedRefundDate = this.getDate(this.applyForm.overWarrantyDate, _balanceRefundPeriodDateType, _balanceRefundPeriodLen);
                    }
                } else {
                    const _dateType = this.contractInfo.paymentSettings[0].overWarrantyPeriodDateType;
                    const _len = this.contractInfo.paymentSettings[0].overWarrantyPeriod;

                    this.applyForm.overWarrantyDate = this.getDate(val, _dateType, _len)
                    console.log(this.applyForm.overWarrantyDate)

                    const _firstRefundPeriodStartType = this.contractInfo.paymentSettings[0].firstRefundPeriodStartType;
                    const _firstRefundPeriodLen = this.contractInfo.paymentSettings[0].firstRefundPeriod;
                    const _firstRefundPeriodDateType = this.contractInfo.paymentSettings[0].firstRefundPeriodDateType;

                    if(_firstRefundPeriodStartType == 0) {// 入职后
                        this.applyForm.contractAgreedRefundDate = this.getDate(val, _firstRefundPeriodDateType, _firstRefundPeriodLen);
                    }

                    if(_firstRefundPeriodStartType == 2) {// 过保
                        this.applyForm.contractAgreedRefundDate = this.getDate(this.applyForm.overWarrantyDate, _firstRefundPeriodDateType, _firstRefundPeriodLen);
                    }
                }
                
            }
        },
        
        overWarrantyDateChange(val) {
            if(this.hasDefaultContract) {
                const _firstRefundPeriodStartType = this.contractInfo.paymentSettings[0].firstRefundPeriodStartType;
                const _firstRefundPeriodLen = this.contractInfo.paymentSettings[0].firstRefundPeriod;
                const _firstRefundPeriodDateType = this.contractInfo.paymentSettings[0].firstRefundPeriodDateType;
                if(_firstRefundPeriodStartType == 2) {// 过保
                    this.applyForm.contractAgreedRefundDate = this.getDate(val, _firstRefundPeriodDateType, _firstRefundPeriodLen);
                }
            }
            this.handleDateChange();
        },
        addWorkingDays(date, numDays) {
            let current = moment(date);  
            let addedDays = 0;  
            while (addedDays < numDays) {  
                current.add(1, 'days');  
                // 如果当前日期不是周末（周六或周日），则增加工作日计数  
                if (current.isoWeekday() < 6) { // 假设周一为1，周日为7  
                    addedDays++;  
                }  
            }
            return current.toDate();  
        },

        querySearch(queryString, cb) {
            let results = queryString ? this.invoiceHeaderOptions.filter(it => it.value.includes(queryString)) : this.invoiceHeaderOptions;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },

    }
}
</script>
<style lang="scss" scope>
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.apply-form-container {
    color: #666;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    .apply-form-main{
        padding: 20px;
        background: #fff;
        border-radius: 8px;
        >h2{
            font-size: 20px;
            line-height: 22px;
            margin: 0 0 10px 0;
        }
        .apply-form{
            >h3{
                font-size: 16px;
                font-weight: bold;
                color: #666666;
                margin: 30px 0 26px 0;
                height: 20px;
                line-height: 20px;
                // background: red;
                position: relative;
                &::before{
                    display: inline-block;
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: #3399FF;
                    border-radius: 50%;
                    margin-right: 8px;
                    vertical-align: baseline;
                }
                &::after{
                    display: inline-block;
                    content: '';
                    height: 131px;
                    width: 2px;
                    background: #F3F3F3;
                    position: absolute;
                    top: 15px;
                    left: 4px;
                }
            }
            .el-select {
                width: 390px;
            }
            .el-form-item{
                .el-form-item__label{
                    padding-left: 24px;
                }
                .input-normal{
                    width: 390px;
                }
                .extra-right{
                    font-size: 16px;
                    color: #38BB9C;
                    margin-left: 30px;
                    cursor: pointer;
                }

                .select-sm{
                    width: 120px;
                }
                .percent-wrap{
                    display: inline-block;
                    width: 280px;
                    // .el-form-item__label{
                    //     width: 120px!important;
                    // }
                }

                .apply-upload{
                    width: 390px;
                    .el-upload--text{
                        >button{
                            width: 100px;
                            height: 40px;
                            border-radius: 4px;
                        }
                    }
                    .el-upload__input{
                        display: none;
                    }
                }

                .apply-bottom-btn{
                    width: 150px;
                    height: 28px;
                    line-height: 26px;
                }
            }
        }
    }
}

.deduction-people-select{
    // background: gray;
    .el-select-dropdown__list{
        padding-bottom: 48px!important;

        .selected{
            .opt-item{
                .opt-item-l, .opt-item-m{
                    color: #38BC9D;
                }
                .opt-item-r{
                    >i{
                        display: block;
                    }
                }
            }
        }
        .opt-top{
            height: 40px;
            background: #F5F7FA;
            .opt-top-cell{
                display: inline-block;
                width: 49%;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #333;
                box-sizing: border-box;
                padding-left: 32px;
            }
        }
        .opt-item{
            width: 100%;
            height: 40px;
            font-size: 0;
            
            .opt-item-l{
                display: inline-block;
                width: 55%;
                max-width: 190px;
                font-size: 14px;
                vertical-align: top;
                padding-left: 10px;
                color: #666;
            }
            .opt-item-m{
                display: inline-block;
                width: 30%;
                font-size: 14px;
                vertical-align: top;
                color: #666;
                overflow: hidden;
                // max-width: 150px;
            }
            .opt-item-r{
                display: inline-block;
                width: 13%;
                font-size: 14px;
                line-height: 40px;
                text-align: right;
                vertical-align: middle;
                >i{
                    display: none;
                }
            }
        }
        
        .select-btns-wrap{
            height: 48px;
            position: absolute;
            bottom: -1px;
            right: 0;
            width: 100%;
            box-sizing: border-box;
            border-top: 1px solid #ccc;
            background: white;
            .sure{
                background: #46d6b5;
                color: white;
                float: right;
                margin-top: 8px;
                margin-right: 10px;
                &:hover{
                    background: #52e0bf;
                }
            }
        }
        
    }
    .empty-data{
        text-align: center;
        padding-bottom: 20px;
        padding-top: 10px;
        .empty-img{
            display: inline-block;
            background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
            background-size: contain;
            width: 160px;
            height: 160px;
        }
        >p{
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0px;
        }
    }
}
</style>