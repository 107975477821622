var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "apply-form-container",
    },
    [
      _c(
        "div",
        { staticClass: "apply-form-main" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.applyTitle))]),
          _c(
            "el-form",
            {
              ref: "applyForm",
              staticClass: "apply-form",
              attrs: {
                model: _vm.applyForm,
                rules: _vm.rules,
                "label-width": "180px",
                "label-position": "left",
              },
            },
            [
              _c("h3", [_vm._v("开票信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "开票主体", prop: "invoiceEntity" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "input-normal",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.applyForm.invoiceEntity,
                        callback: function ($$v) {
                          _vm.$set(_vm.applyForm, "invoiceEntity", $$v)
                        },
                        expression: "applyForm.invoiceEntity",
                      },
                    },
                    _vm._l(_vm.invoiceEntityArray, function (item, index) {
                      return _c("el-option", {
                        key: "entiry_" + index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票抬头", prop: "invoiceHeader" } },
                [
                  _vm.hasDefaultContract
                    ? _c("el-autocomplete", {
                        staticClass: "input-normal",
                        attrs: {
                          "fetch-suggestions": _vm.querySearch,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.applyForm.invoiceHeader,
                          callback: function ($$v) {
                            _vm.$set(_vm.applyForm, "invoiceHeader", $$v)
                          },
                          expression: "applyForm.invoiceHeader",
                        },
                      })
                    : _c("el-input", {
                        staticClass: "input-normal",
                        attrs: { maxlength: "100" },
                        model: {
                          value: _vm.applyForm.invoiceHeader,
                          callback: function ($$v) {
                            _vm.$set(_vm.applyForm, "invoiceHeader", $$v)
                          },
                          expression: "applyForm.invoiceHeader",
                        },
                      }),
                  _c(
                    "a",
                    {
                      staticClass: "extra-right",
                      on: { click: _vm.useCommonInfo },
                    },
                    [_vm._v("使用常用开票信息")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票类型", prop: "invoiceType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "input-normal",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.applyForm.invoiceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.applyForm, "invoiceType", $$v)
                        },
                        expression: "applyForm.invoiceType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "普票", value: "0" } }),
                      _c("el-option", { attrs: { label: "专票", value: "1" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票内容", prop: "invoiceContent" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "input-normal",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.applyForm.invoiceContent,
                        callback: function ($$v) {
                          _vm.$set(_vm.applyForm, "invoiceContent", $$v)
                        },
                        expression: "applyForm.invoiceContent",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "服务费", value: "服务费" },
                      }),
                      _c("el-option", {
                        attrs: { label: "招聘服务费", value: "招聘服务费" },
                      }),
                      _c("el-option", {
                        attrs: { label: "咨询服务费", value: "咨询服务费" },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "其他人力资源服务费",
                          value: "其他人力资源服务费",
                        },
                      }),
                      _c("el-option", {
                        attrs: { label: "信息服务费", value: "信息服务费" },
                      }),
                      _c("el-option", {
                        attrs: { label: "软件服务费", value: "软件服务费" },
                      }),
                      _c("el-option", {
                        attrs: { label: "技术服务费", value: "技术服务费" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票金额", prop: "invoiceAmount" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.applyForm.invoiceAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "invoiceAmount", $$v)
                      },
                      expression: "applyForm.invoiceAmount",
                    },
                  }),
                  _c(
                    "a",
                    {
                      staticClass: "extra-right",
                      on: { click: _vm.handleEditPerformance },
                    },
                    [_vm._v("编辑业绩")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "付款方式", prop: "paymentMethodType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-sm",
                      attrs: {
                        placeholder: "请选择",
                        disabled:
                          _vm.isApplyBalance ||
                          _vm.isApplyNegative ||
                          _vm.isEdit,
                      },
                      model: {
                        value: _vm.applyForm.paymentMethodType,
                        callback: function ($$v) {
                          _vm.$set(_vm.applyForm, "paymentMethodType", $$v)
                        },
                        expression: "applyForm.paymentMethodType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全款", value: "0" } }),
                      _c("el-option", { attrs: { label: "首款", value: "1" } }),
                      !["0", "1"].includes(_vm.paymentMethodType)
                        ? _c("el-option", {
                            attrs: { label: "尾款", value: "2" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.applyForm.paymentMethodType == "1" ||
                  _vm.applyForm.paymentMethodType == "2"
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "percent-wrap",
                          attrs: {
                            label: "付款比例",
                            prop: "paymentProportion",
                            "label-width": "150px",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "select-sm",
                              attrs: {
                                placeholder: "请选择",
                                disabled:
                                  _vm.isApplyBalance || _vm.isApplyNegative,
                              },
                              model: {
                                value: _vm.applyForm.paymentProportion,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.applyForm,
                                    "paymentProportion",
                                    $$v
                                  )
                                },
                                expression: "applyForm.paymentProportion",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "90%", value: "0.9" },
                              }),
                              _c("el-option", {
                                attrs: { label: "80%", value: "0.8" },
                              }),
                              _c("el-option", {
                                attrs: { label: "70%", value: "0.7" },
                              }),
                              _c("el-option", {
                                attrs: { label: "60%", value: "0.6" },
                              }),
                              _c("el-option", {
                                attrs: { label: "50%", value: "0.5" },
                              }),
                              _c("el-option", {
                                attrs: { label: "40%", value: "0.4" },
                              }),
                              _c("el-option", {
                                attrs: { label: "30%", value: "0.3" },
                              }),
                              _c("el-option", {
                                attrs: { label: "20%", value: "0.2" },
                              }),
                              _c("el-option", {
                                attrs: { label: "10%", value: "0.1" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.isApplyNegative
                ? _c(
                    "el-form-item",
                    { attrs: { label: "抵扣方式", prop: "deductionType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "input-normal",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.applyForm.deductionType,
                            callback: function ($$v) {
                              _vm.$set(_vm.applyForm, "deductionType", $$v)
                            },
                            expression: "applyForm.deductionType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "抵扣本单", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.applyForm.deductionType == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "抵扣人选", prop: "deductionPeople" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "deductionPeopleSelect",
                          staticClass: "input-normal",
                          attrs: {
                            "popper-class": "deduction-people-select",
                            placeholder: "搜索候选人姓名或者顾问姓名查找",
                            filterable: "",
                          },
                          model: {
                            value: _vm.applyForm.deductionPeople,
                            callback: function ($$v) {
                              _vm.$set(_vm.applyForm, "deductionPeople", $$v)
                            },
                            expression: "applyForm.deductionPeople",
                          },
                        },
                        [
                          _c("div", { staticClass: "opt-top" }, [
                            _c("div", { staticClass: "opt-top-cell" }, [
                              _vm._v("候选人"),
                            ]),
                            _c("div", { staticClass: "opt-top-cell" }, [
                              _vm._v("顾问"),
                            ]),
                          ]),
                          _vm._l(_vm.deductionUsers, function (people, index) {
                            return _c(
                              "el-option",
                              {
                                key: "people_" + index,
                                attrs: {
                                  label:
                                    people.candidateName +
                                    "——" +
                                    people.deductionUserName,
                                  value:
                                    people.candidateName +
                                    "——" +
                                    people.deductionUserName,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "opt-item",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handlePeopleClick(people)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "opt-item-l ellipsis",
                                        attrs: { title: people.candidateName },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(people.candidateName) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "opt-item-m ellipsis",
                                        attrs: {
                                          title: people.deductionUserName,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(people.deductionUserName) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "opt-item-r" }, [
                                      _c("i", { staticClass: "el-icon-check" }),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _vm.deductionUsers.length == 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "empty-data",
                                  attrs: { slot: "empty" },
                                  slot: "empty",
                                },
                                [
                                  _c("span", { staticClass: "empty-img" }),
                                  _c("p", [_vm._v("暂无数据")]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "select-btns-wrap" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "sure",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBtnHold(1)
                                    },
                                  },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "发票快递地址", prop: "expressAddress" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.applyForm.expressAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "expressAddress", $$v)
                      },
                      expression: "applyForm.expressAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人", prop: "expressReceiver" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "20" },
                    model: {
                      value: _vm.applyForm.expressReceiver,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "expressReceiver", $$v)
                      },
                      expression: "applyForm.expressReceiver",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "expressPhone" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.applyForm.expressPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "expressPhone", $$v)
                      },
                      expression: "applyForm.expressPhone",
                    },
                  }),
                ],
                1
              ),
              _c("h3", [_vm._v("公司信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "companyName" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.applyForm.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "companyName", $$v)
                      },
                      expression: "applyForm.companyName",
                    },
                  }),
                  _c(
                    "a",
                    {
                      ref: "copyBtn",
                      staticClass: "extra-right",
                      attrs: {
                        "data-clipboard-text": _vm.applyForm.companyName,
                      },
                    },
                    [_vm._v("复制发票抬头")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "纳税人识别号", prop: "companyTaxpayerNo" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "20" },
                    model: {
                      value: _vm.applyForm.companyTaxpayerNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "companyTaxpayerNo", $$v)
                      },
                      expression: "applyForm.companyTaxpayerNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册地址", prop: "companyRegisterAddr" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.applyForm.companyRegisterAddr,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "companyRegisterAddr", $$v)
                      },
                      expression: "applyForm.companyRegisterAddr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "companyPhone" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.applyForm.companyPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "companyPhone", $$v)
                      },
                      expression: "applyForm.companyPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开户银行", prop: "companyBankofDeposit" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "50" },
                    model: {
                      value: _vm.applyForm.companyBankofDeposit,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "companyBankofDeposit", $$v)
                      },
                      expression: "applyForm.companyBankofDeposit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "银行账号", prop: "companyBankCardNo" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: { maxlength: "50" },
                    model: {
                      value: _vm.applyForm.companyBankCardNo,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.applyForm,
                          "companyBankCardNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "applyForm.companyBankCardNo",
                    },
                  }),
                ],
                1
              ),
              _c("h3", [_vm._v("业绩相关")]),
              _vm._l(_vm.performances, function (pItem, index) {
                return _c(
                  "el-form-item",
                  {
                    key: "performance_" + index,
                    attrs: {
                      label:
                        pItem.label +
                        "/" +
                        (pItem.type == 0 ? "固定分成" : "分成比例") +
                        "/分成值",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "input-normal",
                      attrs: { disabled: "", title: pItem.value },
                      model: {
                        value: pItem.value,
                        callback: function ($$v) {
                          _vm.$set(pItem, "value", $$v)
                        },
                        expression: "pItem.value",
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm.isApplyNegative
                ? _c(
                    "el-form-item",
                    { attrs: { label: "开票原因", prop: "invoiceReason" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择申请负数发票的原因" },
                          on: { change: _vm.changeInvoiceReason },
                          model: {
                            value: _vm.applyForm.invoiceReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.applyForm, "invoiceReason", $$v)
                            },
                            expression: "applyForm.invoiceReason",
                          },
                        },
                        _vm._l(_vm.invoiceReasonOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isApplyNegative
                ? [
                    _vm.applyForm.invoiceReason == 0
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "离职日期",
                              prop: "resignationDate",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticClass: "input-normal",
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "请选择离职日期",
                              },
                              model: {
                                value: _vm.applyForm.resignationDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.applyForm,
                                    "resignationDate",
                                    $$v
                                  )
                                },
                                expression: "applyForm.resignationDate",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.isApplyNegative && _vm.applyForm.invoiceReason == 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "上传离职证明",
                        prop: "leavingCertificateAttachments",
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "leavingCertificateAttachmentsUpload",
                          staticClass: "apply-upload",
                          attrs: {
                            action:
                              "https://jsonplaceholder.typicode.com/posts/",
                            accept:
                              ".txt, .gif, .jpg, .png, .doc, .docx, .pdf, .zip, .rar",
                            multiple: false,
                            limit: 5,
                            "file-list": _vm.leavingFileList,
                            "on-remove": (file, fileList) => {
                              _vm.handleRemove(file, fileList, 3)
                            },
                            "on-exceed": _vm.handleExceed,
                            "http-request": _vm.handleUploadLeavingFileList,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "只能上传txt、gif、jpg、png、doc、docx、pdf、zip、rar文件，且不超过2.0M"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "入职确认的入职日期",
                          prop: "actualEntryDate",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "input-normal",
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "请选择入职确认中的入职日期",
                          },
                          on: { change: _vm.actualEntryDateChange },
                          model: {
                            value: _vm.applyForm.actualEntryDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.applyForm, "actualEntryDate", $$v)
                            },
                            expression: "applyForm.actualEntryDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "过保日期", prop: "overWarrantyDate" },
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "input-normal",
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "请选择过保日期",
                          },
                          on: { change: _vm.overWarrantyDateChange },
                          model: {
                            value: _vm.applyForm.overWarrantyDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.applyForm, "overWarrantyDate", $$v)
                            },
                            expression: "applyForm.overWarrantyDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "合同约定的回款日期",
                          prop: "contractAgreedRefundDate",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "input-normal",
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "请选择合同约定的回款日期",
                          },
                          on: { change: _vm.handleDateChange },
                          model: {
                            value: _vm.applyForm.contractAgreedRefundDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.applyForm,
                                "contractAgreedRefundDate",
                                $$v
                              )
                            },
                            expression: "applyForm.contractAgreedRefundDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "ownerRemarks" } },
                [
                  _c("el-input", {
                    staticClass: "input-normal",
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 6 },
                      maxlength: "100",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.applyForm.ownerRemarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyForm, "ownerRemarks", $$v)
                      },
                      expression: "applyForm.ownerRemarks",
                    },
                  }),
                ],
                1
              ),
              !(_vm.isApplyBalance || _vm.isApplyNegative)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上传入职证明", prop: "Attachments" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "attachmentUpload",
                          staticClass: "apply-upload",
                          attrs: {
                            action:
                              "https://jsonplaceholder.typicode.com/posts/",
                            accept: ".bmp, .jpg, .png",
                            multiple: false,
                            limit: 5,
                            "file-list": _vm.fileList,
                            "on-remove": _vm.handleRemove,
                            "on-exceed": _vm.handleExceed,
                            "http-request": _vm.handleUpload,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v("只能上传bmp, jpg, png文件，且不超过2.0M")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isApplyNegative
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "上传付款通知函",
                        prop: "paymentNoticeAttachments",
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "paymentNoticeAttachmentsUpload",
                          staticClass: "apply-upload",
                          attrs: {
                            action:
                              "https://jsonplaceholder.typicode.com/posts/",
                            accept: ".txt, .doc, .docx, .pdf, .xls, .xlsx",
                            multiple: false,
                            limit: 5,
                            "file-list": _vm.paymentNoticeFileList,
                            "on-remove": (file, fileList) => {
                              _vm.handleRemove(file, fileList, 2)
                            },
                            "on-exceed": _vm.handleExceed,
                            "http-request":
                              _vm.handleUploadPaymentNoticeFileList,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "只能上传txt、doc、docx、pdf、xls、xlsx文件，且不超过2.0M"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.applyForm.isSaveCommonInfo,
                        callback: function ($$v) {
                          _vm.$set(_vm.applyForm, "isSaveCommonInfo", $$v)
                        },
                        expression: "applyForm.isSaveCommonInfo",
                      },
                    },
                    [_vm._v("保留到常用开票信息中")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "apply-bottom-btn",
                      attrs: {
                        type: "primary",
                        loading: _vm.holdLoading,
                        disabled: _vm.holdLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("applyForm")
                        },
                      },
                    },
                    [_vm._v("提 交")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "apply-bottom-btn",
                      attrs: {
                        loading: _vm.holdLoading,
                        disabled: _vm.holdLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("applyForm")
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("common-ticket-info", {
            ref: "commonTicketInfo",
            on: { "select-cb": _vm.selectCb },
          }),
          _c("offer-dialog", {
            ref: "offerDialog",
            on: { successEditOffer: _vm.editOfferSuccess },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }